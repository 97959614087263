var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('BModal', {
          attrs: {
            "id": "modal-verify-bank-accounts",
            "title": _vm.$t('bankAccount.verifyBankAccount'),
            "title-class": "text-airline font-medium-4 font-weight-bolder",
            "centered": "",
            "body-class": "px-75",
            "size": "md",
            "no-close-on-backdrop": ""
          },
          on: {
            "show": _vm.showHandle
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var cancel = _ref2.cancel;
              return [_c('BButton', {
                staticClass: "center rounded-pill",
                attrs: {
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.cancel')) + " ")]), _c('BButton', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient border-right-0",
                attrs: {
                  "pill": "",
                  "disabled": invalid
                },
                on: {
                  "click": _vm.handleSubmit
                }
              }, [_c('div', {
                staticClass: "d-flex-center px-25"
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.submit')) + " ")])])];
            }
          }], null, true)
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('bankAccount.otp'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('BFormGroup', {
                attrs: {
                  "label-for": "otp"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-nowrap"
                    }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.otp')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('BFormInput', {
                attrs: {
                  "id": "otp",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.removeAccentsUpperCaseFormatter,
                  "placeholder": _vm.$t('bankAccount.ph.otp')
                },
                model: {
                  value: _vm.dataToVerify.otp,
                  callback: function callback($$v) {
                    _vm.$set(_vm.dataToVerify, "otp", $$v);
                  },
                  expression: "dataToVerify.otp"
                }
              }), _c('b-link', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-dark.window",
                  value: _vm.$t('bankAccount.resend'),
                  expression: "$t('bankAccount.resend')",
                  modifiers: {
                    "hover": true,
                    "v-dark": true,
                    "window": true
                  }
                }],
                attrs: {
                  "id": "btnResend"
                },
                on: {
                  "click": _vm.handleResend
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('bankAccount.resend')) + " ")]), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }